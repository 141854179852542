/* @media (min-width: 768px) {
  .home-page-call-now-carousel {
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, .5);
      padding: 20px;
      z-index: 10;
      border-radius: 20px;
      font-size: 40px;
  }

  .animated-word {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .animated-word.fade-out {
    opacity: 0;
  }

  .home-page-phone-link {
    color: red;
  }

  .home-page-phone-link:hover {
    color: green;
  }


  .home-page-images-and-details {
    display: flex;
    flex-direction: column;
  }

  .home-page-map-and-business-profile {
    display: flex;
    flex-direction: column;
  }

  .home-page-details-demographics {
    display: flex;
    justify-content: space-between;
  }

  .home-page-details-text {
    background-color: rgba(0, 255, 255, 0.295);
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: black 2px 2px 4px 2px;
  }

  .repair-man-img {
    max-width: 450px;
    max-height: 450px;
    border-radius: 50%;
  }

  .home-page-details-1 {
    display: flex;
  }

  .home-page-details-title {
    font-size: 30px;
    text-align: center;
  }

  .home-page-details-p {
    margin: 40px;
    font-size: 20px;
  }
} */





/* Media Query for small screens (adjust the max-width as needed) */
/* @media (max-width: 768px) {
  .home-page-call-now-carousel {
    font-size: 16px;
  }
  .home-page-container {
    padding: 10px;
  }

  .home-page-content-container {
    display: flex;
    flex-wrap: wrap;

  }

  .home-page-call-now-carousel {
    font-size: 18px;
  }

  .home-page-details-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .repair-man-img {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .home-page-details-title {
    font-size: 18px;
  }

  .home-page-details-p {
    font-size: 14px;
  }
} */

@media screen and (min-width: 1024px) {
  .custom-homepage-grid-images {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }
}

@media screen and (min-width: 1284px) {
  .custom-homepage-grid-images {
    display: grid;
    grid-template-columns: .8fr 2fr 1fr;
  }
}

.custom-homepage-individual-img {
  box-shadow: black 0px 0px 7px 2px;
  margin-bottom: 5px;
}

.custom-homepage-individual-img:hover {
  box-shadow: blue 0px 0px 5px 2px;
  cursor: pointer;
}
