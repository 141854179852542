/*

.image-carousel {
  margin-top: 5px;
  border-radius: 20px;
  position: relative;
  max-width: 100%;
  max-height: 350px;
  overflow: hidden;
  opacity: .9;
  box-shadow: black 2px 2px 5px 2px;
}

.carousel-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.slick-slide {
  transition: transform 0.5s ease-in-out;
} */

.image-carousel {
  width: 100%
}

button.slick-arrow.slick-next {
  display: none;
  margin-right: 40px;
  background-color: black;
  border-radius: 20px;
}
