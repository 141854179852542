/* .footer-container {
  position:absolute;
  bottom: 0;
} */

/* .footer-container {
  display: flex;
  justify-content: space-around;
  border-top: black solid 1px;
  background-color: rgba(189, 189, 189, 0.568);
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.footer-phone-box {
  display: flex;
  text-align: center;
  border: black solid 2px;
  width: 205px;
  box-shadow: rgb(77, 75, 75) 4px 4px 7px 1px;
  border-radius: 7px;
}

.fa-phone {
  font-size: 30px;
  padding-left: 5px;
}

.footer-phone-icon-container {
  display: flex;
  align-items: center;
}

.footer-phone-number {
  display: flex;
  flex-direction: column;
  width: 170px;
}

.footer-address {
  text-decoration: underline;
}

.footer-phone-link {
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  color: rgb(0, 129, 133);
}

.footer-phone-link:hover {
  text-decoration: underline;
}

.footer-quick-links {
  display: flex;
  flex-direction: column;
}

.footer-link {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 5px;
  transition: padding 1s;
}

.footer-link:hover{
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 153, 255);
}

.footer-social-img {
  height: 60px;
  width: 60px;
  transition: 1s;
  border-radius: 20px;
  box-shadow: rgb(59, 58, 58) 0px 0px 7px 2px;
  margin-bottom: 3px;
}

.footer-social-img:hover {
  box-shadow: rgb(0, 182, 228) 2px 2px 10px 5px;
  height: 65px;
  width: 65px;
}

.footer-social-links-container {
  display: flex;
  flex-direction: column;
  align-items:center ;
}

.hours-saturday {
  color: red;
  margin-left: 10px;
}

.hours-saturday-container {
  display: flex;
  font-weight: bold;
  margin-top: -15px;
}

.hours-days {
  font-weight: bold;
}

.hours-times {
  color: green;
  font-size: 18px;
}

.footer-icon {
  margin-top: 30px;
  height: 200px;
  border-radius: 50%;
} */

.custom-footer-hours {
  text-decoration: underline;
}

.custom-hover-social-img {
  transition: 1s;
  border-radius: 15px;
}

.custom-hover-social-img:hover {
  height: 60px;
  width: 60px;
  box-shadow: green 0px 0px 2px 2px;
}

.custom-footer-number:hover {
  color: green;
}

.custom-footer-link {
  transition: 1s;
}

.custom-footer-link:hover {
  padding-left: 20px;
  color: rgb(208, 228, 255);
}
