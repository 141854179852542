.tahar-response-modal-container {
  background-color: aliceblue;
  margin: 10px;
  width: 400px;
  padding: 20px;
  border: rgb(118, 118, 255) solid 2px;
  box-shadow: black 2px 2px 4px 2px;
}

.tahar-response-name {
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
}

.custom-tahar-response-button {
  border: rgb(0, 183, 255) solid 1px;
  box-shadow: black 2px 2px 3px 1px;
}

.custom-tahar-response-button:hover {
  box-shadow: rgb(12, 156, 156) 2px 2px 3px 1px;
}
