.about-custom-container {
  background-color: rgba(255, 255, 255, 0.644);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  border: solid black 1px;
  box-shadow: black 0px 0px 2px 1px;
}

.about-door-img {
  border-radius: 10px;
  border: solid black 1px;
  box-shadow: black 0px 0px 2px 1px;
}
